import React from "react";
import styled from "styled-components/macro";
import { css } from "styled-components";
import { Link } from "react-router-dom";
import data from "../content.json";

import Header from "./Header";
import {
  FONT_STYLE,
  SPACING,
  PATTERN,
  DEVICE,
  CHARACTERS,
  ROTATE
} from "../constants";

const MobileMessage = styled.div`
  ${ROTATE.MESSAGE};
`;

const MobileHeadline = styled.h1`
  display: inline-block;
  ${FONT_STYLE.QUIZ_HEADLINE}
`;

const MobileBody = styled.p`
  display: inline-block;
  ${FONT_STYLE.QUIZ_BODY}
  margin: 0.5em 0 2em;
`;

const MobileAnimation = styled.div`
  ${ROTATE.IMAGE};
`;

const PageContainer = styled.div`
  ${PATTERN.FLEXCONTAINER}
  align-content: flex-start;
`;

const Headline = styled.h1`
  display: none;

  @media screen and (min-width: ${DEVICE.PHONE_LANDSCAPE}) {
    display: inline-block;
    ${FONT_STYLE.QUIZ_HEADLINE}
    ${PATTERN.FADEIN}
  }
`;

const Paragraph = styled.p`
  display: none;

  @media screen and (min-width: ${DEVICE.PHONE_LANDSCAPE}) {
    display: inline-block;
    ${FONT_STYLE.QUIZ_BODY}
    margin-bottom: ${SPACING.LARGE};
    ${PATTERN.FADEIN}
    width: 100%;
    margin: 0 0 20px;
  }

  @media screen and (min-width: ${DEVICE.TABLET_LANDSCAPE}) {
    margin: 0 0 24px;
  }

  @media screen and (min-width: ${DEVICE.LAPTOP}) {
    margin: 0 0 48px;
  }
`;

const OptionContainer = styled.div`
  display: none;

  @media screen and (min-width: ${DEVICE.PHONE_LANDSCAPE}) {
    display: flex;
    flex-grow: 1;
    align-items: center;
    ${PATTERN.FADEIN}
    width: 100%;
  }
  @media screen and (min-width: ${DEVICE.TABLET_PORTRAIT}) {
    align-items: center;
  }
`;

const Option = styled(Link)`
    display: inline-block;
    flex-basis: 25%;
    height: auto;
    transition: transform 0.3s ease;
    height:37vh;
    
    @media screen and (min-width: ${DEVICE.TABLET_LANDSCAPE}) {
      height: 45vh;  
    }

    ${props =>
      props.character === 1 &&
      css`
        background: url(${CHARACTERS[0]}) no-repeat center center;
        background-size: auto 100%;
        transform: scaleX(1) scaleY(1);

        &:hover {
          transform: scaleX(1.1) scaleY(1.1);
        }
      `}

    ${props =>
      props.character === 2 &&
      css`
        background: url(${CHARACTERS[1]}) no-repeat center center;
        background-size: auto 100%;
        transform: scaleX(-1) scaleY(1);

        &:hover {
          transform: scaleX(-1.1) scaleY(1.1);
        }
      `}

    ${props =>
      props.character === 3 &&
      css`
        background: url(${CHARACTERS[2]}) no-repeat center center;
        background-size: auto 100%;
        transform: scaleX(1) scaleY(1);

        &:hover {
          transform: scaleX(1.1) scaleY(1.1);
        }
      `}

    ${props =>
      props.character === 4 &&
      css`
        background: url(${CHARACTERS[3]}) no-repeat center center;
        background-size: auto 100%;
        transform: scaleX(-1) scaleY(1);

        &:hover {
          transform: scaleX(-1.1) scaleY(1.1);
        }
      `}
`;

class Characters extends React.Component {
  componentDidMount() {
    this.props.updateClickstream(data.characters.headline.join(" "));
  }
  render() {
    return (
      <PageContainer>
        <Header updateClickstream={this.props.updateClickstream} />
        <MobileMessage>
          <MobileHeadline>Please rotate your device.</MobileHeadline>
          <MobileBody>
            This experience is optimized for landscape mode.
          </MobileBody>
          <MobileAnimation />
        </MobileMessage>
        <Headline>{data.characters.headline}</Headline>
        <Paragraph>{data.characters.body[0]}</Paragraph>
        <OptionContainer>
          <Option
            to="/explore"
            character={1}
            onClick={() => {
              this.props.setCharacter(1);
              this.props.updateLastClick("Character 1");
            }}
          />
          <Option
            to="/explore"
            character={2}
            onClick={() => {
              this.props.setCharacter(2);
              this.props.updateLastClick("Character 2");
            }}
          />
          <Option
            to="/explore"
            character={3}
            onClick={() => {
              this.props.setCharacter(3);
              this.props.updateLastClick("Character 3");
            }}
          />
          <Option
            to="/explore"
            character={4}
            onClick={() => {
              this.props.setCharacter(4);
              this.props.updateLastClick("Character 4");
            }}
          />
        </OptionContainer>
      </PageContainer>
    );
  }
}

export default Characters;
