import firebase from "firebase/app";
import "firebase/database";

const config = {
  apiKey: "AIzaSyAkEqG4-xU57v5liIgIk3TL9aogOQiTEQQ",
  authDomain: "ubs-interactives.firebaseapp.com",
  databaseURL: "https://ubs-interactives.firebaseio.com",
  projectId: "ubs-interactives",
  storageBucket: "ubs-interactives.appspot.com",
  messagingSenderId: "285149281643"
};

firebase.initializeApp(config);
export default firebase;
