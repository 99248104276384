import React from "react";
import styled from "styled-components/macro";
import { Link } from "react-router-dom";
import data from "../content.json";
import { FONT_STYLE, COLOR, PATTERN, DEVICE, ROTATE } from "../constants";
import Header from "./Header";

const PageContainer = styled.div`
  ${PATTERN.FLEXCONTAINER};
`;

const MobileMessage = styled.div`
  ${ROTATE.MESSAGE};
`;

const MobileHeadline = styled.h1`
  display: inline-block;
  ${FONT_STYLE.QUIZ_HEADLINE}
`;

const MobileBody = styled.p`
  display: inline-block;
  ${FONT_STYLE.QUIZ_BODY}
  margin: 0.5em 0 2em;
`;

const MobileAnimation = styled.div`
  ${ROTATE.IMAGE};
`;

const ContentContainer = styled.div`
  display: none;

  @media screen and (min-width: ${DEVICE.PHONE_LANDSCAPE}) {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    ${PATTERN.FADEIN}
    justify-content: center;
  }
`;

const Headline = styled.h1`
  ${FONT_STYLE.DISPLAY_HEADLINE}
  margin-bottom: 24px;
`;

const Emphasis = styled.span`
  ${FONT_STYLE.DISPLAY_HEADLINE_EMPHASIS}
  display: block;
`;

const Paragraph = styled.p`
  ${FONT_STYLE.DISPLAY_BODY}
  max-width: 780px;
  margin: 0 auto 20px 0;
`;

const ButtonContainer = styled.div`
  display: none;
  align-self: flex-end;

  @media screen and (min-width: ${DEVICE.PHONE_LANDSCAPE}) {
    padding: 0;
    display: flex;
    justify-content: flex-end;
    width: 100%;
    height: auto;
  }
`;

const StartButton = styled(Link)`
  ${FONT_STYLE.BUTTON};
  background: ${COLOR.ACCENT_2};
  color: white;
  padding: 1em 0.9em 1em 1.1em;
  line-height: 1em;
  font-size: 1em;
  text-align: center;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  ${PATTERN.FADEIN}
`;

const Triangle = styled.div`
  width: 0;
  height: 0;
  border-top: 1.5em solid transparent;
  border-bottom: 1.5em solid transparent;
  border-left: 1.5em solid #8aa75d;
  ${PATTERN.FADEIN}
`;

class Landing extends React.Component {
  componentDidMount() {
    this.props.updateClickstream(data.landing.headline.join(" "));
  }
  render() {
    return (
      <PageContainer>
        <Header updateClickstream={this.props.updateClickstream} />
        <MobileMessage>
          <MobileHeadline>Please rotate your device.</MobileHeadline>
          <MobileBody>
            This experience is optimized for landscape mode.
          </MobileBody>
          <MobileAnimation />
        </MobileMessage>
        <ContentContainer>
          <Headline>
            {data.landing.headline[0]}{" "}
            <Emphasis>{data.landing.headline[1]}</Emphasis>
          </Headline>
          <Paragraph>{data.landing.body[0]}</Paragraph>
        </ContentContainer>
        <ButtonContainer>
          <StartButton
            to="/characters"
            onClick={() => {
              this.props.updateLastClick(data.landing.cta[0]);
            }}
          >
            {data.landing.cta[0]}
          </StartButton>
          <Triangle />
        </ButtonContainer>
      </PageContainer>
    );
  }
}

export default Landing;
