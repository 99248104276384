import React from "react";
import styled from "styled-components/macro";
import { Link } from "react-router-dom";
import {
  PATTERN,
  FONT_STYLE,
  SPACING,
  SPRITE,
  DEVICE,
  KEYFRAMES,
  ROTATE
} from "../constants";
import Header from "./Header";
import data from "../content.json";

const EndingContainer = styled.div`
  ${PATTERN.FLEXCONTAINER}
  flex-direction: column;
  align-items: flex-start;
  flex-wrap: nowrap;
`;

const MobileMessage = styled.div`
  ${ROTATE.MESSAGE};
`;

const MobileHeadline = styled.h1`
  display: inline-block;
  ${FONT_STYLE.QUIZ_HEADLINE}
`;

const MobileBody = styled.p`
  display: inline-block;
  ${FONT_STYLE.QUIZ_BODY}
  margin: 0.5em 0 2em;
`;

const MobileAnimation = styled.div`
  ${ROTATE.IMAGE};
`;

const ContentContainer = styled.div`
  display: none;

  @media screen and (min-width: ${DEVICE.PHONE_LANDSCAPE}) {
    flex-grow: 0;
    display: flex;
    flex-direction: column;
    width: 100%;
  }
`;
const Headline = styled.h1`
  display: none;

  @media screen and (min-width: ${DEVICE.PHONE_LANDSCAPE}) {
    display: inline-block;
    ${FONT_STYLE.QUIZ_HEADLINE}
    ${PATTERN.FADEIN}
  }
`;

const Paragraph = styled.p`
  display: none;

  @media screen and (min-width: ${DEVICE.PHONE_LANDSCAPE}) {
    display: inline-block;
    ${FONT_STYLE.QUIZ_BODY}
    margin-bottom: ${SPACING.LARGE};
    ${PATTERN.FADEIN}
    width: 100%;
    margin: 0 0 20px;
  }

  @media screen and (min-width: ${DEVICE.TABLET_LANDSCAPE}) {
    margin: 0 0 24px;
  }

  @media screen and (min-width: ${DEVICE.LAPTOP}) {
    margin: 0 0 48px;
  }
`;
const Options = styled.div`
  display: none;

  @media screen and (min-width: ${DEVICE.PHONE_LANDSCAPE}) {
    display: flex;
    flex-grow: 0;
    justify-content: space-around;
    width: 100%;
    margin: 0 auto 0;
    ${PATTERN.FADEIN}
  }

  @media screen and (min-width: ${DEVICE.TABLET_PORTRAIT}) {
    margin: 2em auto 0;
    width: 90%;
  }

  @media screen and (min-width: ${DEVICE.TABLET_LANDSCAPE}) {
    width: 90%;
  }
`;

const Option = styled.div`
  width: 40%;
  height: auto;
  flex-grow: -0;
  max-width: 400px;
  display: flex;

  margin: 0 0 0 1em;

  &:first-child {
    margin: 0;
  }
`;

const OptionImage = styled.div`
  width: 100%;
  height: 10vw;
  margin: 0 auto 10px;

  ${props => (props.type === "calendar" ? SPRITE.CALENDAR : SPRITE.PHONE)}
`;

const OptionText = styled.p`
  ${FONT_STYLE.OPTION}
  margin: 0;
  position: relative;
  padding-right: 1.6em;

  &::after {
    ${PATTERN.CHEVRON}
  }
`;
// const OptionInternalLink = styled(Link)`
//     text-decoration: none;
// `

const OptionPhone = styled.div`
  text-decoration: none;
`;

const PhoneText = styled.p`
  ${FONT_STYLE.OPTION}
  margin: 0;
  position: relative;
  padding-right: 1.6em;
`;

const PhoneNumber = styled.span`
  display: block;
`;

const OptionExternalLink = styled.a`
  text-decoration: none;

  &:hover {
    p::after {
      animation: ${KEYFRAMES.CHEVRON} 0.25s infinite alternate;

      @media screen and (min-width: ${DEVICE.PHONE_LANDSCAPE}) {
        animation: ${KEYFRAMES.CHEVRON_PHONE_LANDSCAPE} 0.25s infinite alternate;
      }

      @media screen and (min-width: ${DEVICE.TABLET_PORTRAIT}) {
        animation: ${KEYFRAMES.CHEVRON_TABLET_PORTRAIT} 0.25s infinite alternate;
      }

      @media screen and (min-width: ${DEVICE.TABLET_LANDSCAPE}) {
        animation: ${KEYFRAMES.CHEVRON_TABLET_LANDSCAPE} 0.25s infinite
          alternate;
      }

      @media screen and (min-width: ${DEVICE.LAPTOP}) {
        animation: ${KEYFRAMES.CHEVRON_LAPTOP} 0.25s infinite alternate;
      }
    }
  }
`;
const ButtonWrapper = styled.div`
  display: none;

  @media screen and (min-width: ${DEVICE.PHONE_LANDSCAPE}) {
    width: 100%;
    flex-grow: 8;
    display: flex;
    align-self: flex-end;
    justify-content: flex-end;
    margin-top: ${SPACING.LARGE};
    ${PATTERN.FADEIN}
    height: auto;
    position: relative;
    z-index: 210;
  }
`;
const LegalLink = styled(Link)`
  ${FONT_STYLE.BUTTON}
  cursor: pointer;
  position: relative;
  padding-right: 1.6em;
  align-self: flex-end;

  &::after {
    ${PATTERN.CHEVRON}
  }

  &:hover {
    &::after {
      animation: ${KEYFRAMES.CHEVRON} 0.25s infinite alternate;

      @media screen and (min-width: ${DEVICE.PHONE_LANDSCAPE}) {
        animation: ${KEYFRAMES.CHEVRON_PHONE_LANDSCAPE} 0.25s infinite alternate;
      }

      @media screen and (min-width: ${DEVICE.TABLET_PORTRAIT}) {
        animation: ${KEYFRAMES.CHEVRON_TABLET_PORTRAIT} 0.25s infinite alternate;
      }

      @media screen and (min-width: ${DEVICE.TABLET_LANDSCAPE}) {
        animation: ${KEYFRAMES.CHEVRON_TABLET_LANDSCAPE} 0.25s infinite
          alternate;
      }

      @media screen and (min-width: ${DEVICE.LAPTOP}) {
        animation: ${KEYFRAMES.CHEVRON_LAPTOP} 0.25s infinite alternate;
      }
    }
  }
`;
class Contact extends React.Component {
  componentDidMount() {
    this.props.updateClickstream(data.contact.headline.join(" "));
  }

  render() {
    return (
      <EndingContainer>
        <Header updateClickstream={this.props.updateClickstream} />
        <MobileMessage>
          <MobileHeadline>Please rotate your device.</MobileHeadline>
          <MobileBody>
            This experience is optimized for landscape mode.
          </MobileBody>
          <MobileAnimation />
        </MobileMessage>
        <ContentContainer>
          <Headline>{data.contact.headline}</Headline>
          <Paragraph>{data.contact.body}</Paragraph>
        </ContentContainer>
        <Options>
          <Option>
            <OptionExternalLink
              href={data.contact.options[0].destination[1]}
              target="_blank"
              onClick={() => {
                this.props.updateClickstream(
                  data.contact.options[0].destination[1],
                  data.contact.options[0].copy.join(" ")
                );
              }}
            >
              <OptionImage type="calendar" />
              <OptionText>{data.contact.options[0].copy}</OptionText>
            </OptionExternalLink>
          </Option>
          <Option>
            <OptionPhone>
              <OptionImage type="phone" />
              <PhoneText>
                {data.contact.options[1].copy[0]}
                <PhoneNumber>{data.contact.options[1].copy[1]}</PhoneNumber>
              </PhoneText>
            </OptionPhone>
          </Option>
        </Options>
        <ButtonWrapper>
          <LegalLink
            to="/legal"
            onClick={() => {
              this.props.updateLastClick(data.contact.btn.join(" "));
            }}
          >
            {data.contact.btn}
          </LegalLink>
        </ButtonWrapper>
      </EndingContainer>
    );
  }
}

export default Contact;
