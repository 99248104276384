import "react-app-polyfill/ie11";
import "react-app-polyfill/stable";
import "core-js/stable";
import React from "react";
import ReactDOM from "react-dom";
import "normalize.css";
import "./index.css";
import App from "./components/App";
import "url-search-params-polyfill";

ReactDOM.render(<App />, document.getElementById("root"));
