import React from "react";
import styled from "styled-components/macro";
import { css } from "styled-components";
import { Route } from "react-router-dom";
import {
  FONT_STYLE,
  SPACING,
  KEYFRAMES,
  PATTERN,
  DEVICE,
  SMALLCHARACTERS,
  ROTATE
} from "../constants";
import data from "../content.json";
import Header from "./Header";
import Frame from "./Frame";
import Encounters from "./Encounters";

const MobileMessage = styled.div`
  ${ROTATE.MESSAGE};
`;

const MobileHeadline = styled.h1`
  display: inline-block;
  ${FONT_STYLE.QUIZ_HEADLINE}
`;

const MobileBody = styled.p`
  display: inline-block;
  ${FONT_STYLE.QUIZ_BODY}
  margin: 0.5em 0 2em;
`;

const MobileAnimation = styled.div`
  ${ROTATE.IMAGE};
`;

const PageContainer = styled.div`
  ${PATTERN.FLEXCONTAINER}
  align-content: flex-start;
`;

const Character = styled.div`
  display: none;

  @media screen and (min-width: ${DEVICE.PHONE_LANDSCAPE}) {
    position: absolute;
    right: 0vh;
    bottom: 5vh;
    display: inline-block;
    width: 10vh;
    height: 28vh;
    transition: right 0.5s ease-out, bottom 0.5s ease-out;
    will-change: right, bottom;
    z-index: 100;
    background-size: contain;


    ${props =>
      props.character === 1 &&
      css`
        background: url(${SMALLCHARACTERS[0]}) no-repeat center center;
        background-size: contain;
      `}

    ${props =>
      props.character === 2 &&
      css`
        background: url(${SMALLCHARACTERS[1]}) no-repeat center center;
        background-size: contain;
      `}

    ${props =>
      props.character === 3 &&
      css`
        background: url(${SMALLCHARACTERS[2]}) no-repeat center center;
        background-size: contain;
      `}

    ${props =>
      props.character === 4 &&
      css`
        background: url(${SMALLCHARACTERS[3]}) no-repeat center center;
        background-size: contain;
      `}

    ${props =>
      props.frame === 0 &&
      props.path === "Retirement" &&
      css`
        ${PATTERN.FROMRIGHT}
        transform: scaleX(-1);
        right: 27vh;
        bottom: 5vh;
      `}

    ${props =>
      props.frame === 1 &&
      props.path === "Retirement" &&
      css`
        transform: scaleX(1);
        right: 46vh;
        bottom: 15vh;
      `}
    
    ${props =>
      props.frame === 2 &&
      props.path === "Retirement" &&
      css`
        transform: scaleX(1);
        right: 62vh;
        bottom: 8vh;
      `}

    ${props =>
      props.frame === 3 &&
      props.path === "Retirement" &&
      css`
        transform: scaleX(-1);
        right: 48vh;
        bottom: 32vh;
      `}
    
    ${props =>
      props.frame === 4 &&
      props.path === "Retirement" &&
      css`
        transform: scaleX(1);
        right: 52vh;
        bottom: 5vh;
      `}
        

    ${props =>
      props.frame === 0 &&
      props.path === "Education" &&
      css`
        transform: scaleX(-1);
        ${PATTERN.FROMRIGHT}
        right: 20vh;
        bottom: 5vh;
      `}

    ${props =>
      props.frame === 1 &&
      props.path === "Education" &&
      css`
        transform: scaleX(1);
        right: 26vh;
        bottom: 27vh;
      `}
    
    ${props =>
      props.frame === 2 &&
      props.path === "Education" &&
      css`
        transform: scaleX(1);
        right: 62vh;
        bottom: 8vh;
      `}

    ${props =>
      props.frame === 3 &&
      props.path === "Education" &&
      css`
        transform: scaleX(-1);
        right: 53vh;
        bottom: 26vh;
      `}
  
    ${props =>
      props.frame === 4 &&
      props.path === "Education" &&
      css`
        transform: scaleX(1);
        right: 49vh;
        bottom: 11vh;
      `}
  }
`;

const CaptionContainer = styled.div`
  display: none;

  @media screen and (min-width: ${DEVICE.PHONE_LANDSCAPE}) {
    display: inline-block;
    ${PATTERN.FADEIN}
    width: 40%;
    margin: 0;
    position: absolute;
    bottom: 24px;
    left: 24px;

    p {
      ${FONT_STYLE.DISPLAY_CAPTION}
      margin: 0;
    }
  }

  @media screen and (min-width: ${DEVICE.TABLET_PORTRAIT}) {
    width: 30%;
  }

  @media screen and (min-width: ${DEVICE.TABLET_LANDSCAPE}) {
    width: 36%;
  }

  @media screen and (min-width: ${DEVICE.LAPTOP}) {
    width: 34%;
  }
`;

const ButtonContainer = styled.div`
  display: none;

  @media screen and (min-width: ${DEVICE.PHONE_LANDSCAPE}) {
    width: 100%;
    flex-grow: 1;
    display: flex;
    align-self: flex-end;
    justify-self: flex-end;
    justify-content: flex-end;
    margin-top: ${SPACING.LARGE};
    ${PATTERN.FADEIN}
    height: auto;
    position: relative;
    z-index: 300;
  }

  button {
    ${FONT_STYLE.BUTTON}
    background: transparent;
    border: 0;
    cursor: pointer;
    position: relative;
    padding-right: 1.6em;
    align-self: flex-end;
    z-index: 200;

    &::after {
      ${PATTERN.CHEVRON}
    }

    &:focus {
      outline: none;
    }

    &:hover {
      &::after {
        animation: ${KEYFRAMES.CHEVRON} 0.25s infinite alternate;

        @media screen and (min-width: ${DEVICE.PHONE_LANDSCAPE}) {
          animation: ${KEYFRAMES.CHEVRON_PHONE_LANDSCAPE} 0.25s infinite
            alternate;
        }

        @media screen and (min-width: ${DEVICE.TABLET_PORTRAIT}) {
          animation: ${KEYFRAMES.CHEVRON_TABLET_PORTRAIT} 0.25s infinite
            alternate;
        }

        @media screen and (min-width: ${DEVICE.TABLET_LANDSCAPE}) {
          animation: ${KEYFRAMES.CHEVRON_TABLET_LANDSCAPE} 0.25s infinite
            alternate;
        }

        @media screen and (min-width: ${DEVICE.LAPTOP}) {
          animation: ${KEYFRAMES.CHEVRON_LAPTOP} 0.25s infinite alternate;
        }
      }
    }
  }
`;

class Path extends React.Component {
  componentDidMount() {
    // this.props.checkProgress();
    if (
      this.props.path === "Retirement" &&
      window.location.pathname === "/education"
    ) {
      this.props.setPath("Education");
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.transition === 'isEntered' && prevProps.transition !== 'isEntered') {
      this.props.updateClickstream(
        this.props.path === "Retirement"
          ? data.retirement.frame[this.props.frame].headline
          : data.education.frame[this.props.frame].headline
      );
    }
    // if (this.props.canAdvance !== prevState.canAdvance) {
    //   this.props.checkProgress();
    // }
  }

  render() {
    return (
      <Route
        render={({ history }) => (
          <PageContainer>
            <Header updateClickstream={this.props.updateClickstream} />
            <MobileMessage>
              <MobileHeadline>Please rotate your device.</MobileHeadline>
              <MobileBody>
                This experience is optimized for landscape mode.
              </MobileBody>
              <MobileAnimation />
            </MobileMessage>
            <Character
              path={this.props.path}
              frame={this.props.frame}
              character={this.props.character}
            />
            <Frame
              path={this.props.path}
              frame={this.props.frame}
              // updateClickstream={this.props.updateClickstream}
              // updateLastClick={this.props.updateLastClick}
            />
            {this.props.path === "Retirement" &&
              data.retirement.frame[this.props.frame].captions.length > 0 && (
                <CaptionContainer>
                  {data.retirement.frame[this.props.frame].captions.map(
                    (caption, index) => (
                      <p key={index}>{caption}</p>
                    )
                  )}
                </CaptionContainer>
              )}
            {this.props.path === "Education" &&
              data.education.frame[this.props.frame].captions.length > 0 && (
                <CaptionContainer>
                  {data.education.frame[this.props.frame].captions.map(
                    (caption, index) => (
                      <p key={index}>{caption}</p>
                    )
                  )}
                </CaptionContainer>
              )}
            <ButtonContainer>
              <button
                onClick={() => {
                  if (this.props.frame !== 4) {
                    this.props.setTransition("isExiting");
                    setTimeout(() => {
                      this.props.setNextFrame(
                        this.props.frame,
                        this.props.path
                      );
                      this.props.updateLastClick("Next");
                      this.props.setTransition("isExited");
                      this.props.setTransition("isEntering");
                      setTimeout(() => {
                        this.props.setTransition("isEntered");
                      }, 500);
                    }, 500);
                  } else {
                    this.props.setPathComplete(this.props.path);
                    this.props.setNextFrame(-1, this.props.path);
                    this.props.updateLastClick("Next");
                    if (
                      (this.props.path === "Retirement" &&
                        !this.props.educationComplete) ||
                      (this.props.path === "Education" &&
                        !this.props.retirementComplete)
                    ) {
                      history.push("/explore");
                    } else {
                      history.push("/congrats");
                    }
                  }
                }}
              >
                Next
              </button>
            </ButtonContainer>
            <Encounters
              path={this.props.path}
              frame={this.props.frame}
              data={this.props.data}
              growth={this.props.growth}
              setGrowth={this.props.setGrowth}
              transition={this.props.transition}
              setTransition={this.props.setTransition}
              updateClickstream={this.props.updateClickstream}
              // updateLastClick={this.props.updateLastClick}
            />
          </PageContainer>
        )}
      />
    );
  }
}

export default Path;
