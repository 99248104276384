import React from "react";
import styled from "styled-components/macro";
import { BrowserRouter, Switch, Route } from "react-router-dom";
import firebase from "../firebase.js";
import moment from "moment";
import { DEVICE, SPACING, COLOR } from "../constants";
import Landing from "./Landing";
import Characters from "./Characters";
import Explore from "./Explore";
import Path from "./Path";
import Congrats from "./Congrats";
import Contact from "./Contact";
import Legal from "./Legal";
import data from "../content.json";
import EducationRoad from "../img/education-road-clear.png";
import RetirementRoad from "../img/retirement-road-clear.png";

const AppContainer = styled.div`
  width: 100%;
  height: 100%;
  border: ${SPACING.MEDIUM} solid ${COLOR.TERTIARY};
  background: white;

  @media screen and (min-width: ${DEVICE.PHONE_LANDSCAPE}) {
    border: ${SPACING.SMALL} solid ${COLOR.TERTIARY};
    transition: background-position 0.5s ease-out;
    will-change: background-position;
    background: white
      url(${() => {
        if (window.location.pathname === "/education") return EducationRoad;
        if (window.location.pathname === "/retirement") return RetirementRoad;
        return "none";
      }})
      no-repeat;
    background-size: auto 500vh;
    background-origin: padding-box;
    background-position: ${props => {
      if (props.frame === 0 && window.location.pathname === "/retirement")
        return "calc(100% - 15vh) -0vh";
      if (props.frame === 1 && window.location.pathname === "/retirement")
        return "calc(100% - 25vh) -100vh";
      if (props.frame === 2 && window.location.pathname === "/retirement")
        return "calc(100% - 15vh) -200vh";
      if (props.frame === 3 && window.location.pathname === "/retirement")
        return "calc(100% - -10vh) -300vh";
      if (props.frame === 4 && window.location.pathname === "/retirement")
        return "calc(100% - -42vh) -400vh";
      if (props.frame === 0 && window.location.pathname === "/education")
        return "calc(100% - 15vh) -0vh";
      if (props.frame === 1 && window.location.pathname === "/education")
        return "calc(100% - 22vh) -100vh";
      if (props.frame === 2 && window.location.pathname === "/education")
        return "calc(100% - 15vh) -200vh";
      if (props.frame === 3 && window.location.pathname === "/education")
        return "calc(100% - 6vh) -300vh";
      if (props.frame === 4 && window.location.pathname === "/education")
        return "calc(100% - -37vh) -400vh";
      return "-100vw -0vh";
    }};
  }

  @media screen and (min-width: ${DEVICE.TABLET_PORTRAIT}) {
    border: ${SPACING.MEDIUM} solid ${COLOR.TERTIARY};
  }

  @media screen and (min-width: ${DEVICE.TABLET_LANDSCAPE}) {
    border: ${SPACING.LARGE} solid ${COLOR.TERTIARY};
  }

  @media screen and (min-width: ${DEVICE.LAPTOP}) {
    border: ${SPACING.LARGE} solid ${COLOR.TERTIARY};
  }
`;

class App extends React.Component {
  state = {
    marketingId: null,
    // offsite: [],
    clickUrl: null,
    lastClick: null,
    clickstream: [],
    character: 1,
    path: data.explore.paths[0],
    frame: 0,
    encounter: 0,
    retirementComplete: false,
    educationComplete: false,
    growth: 0,
    transition: null
  };

  // Sets the character prop in state to the name of the selected character

  setCharacter = character => {
    this.setState({
      character: character
    });
  };

  // Path, Frame & Encounter

  setPath = path => {
    this.setState({
      path: path
    });
  };

  setFrame = frame => {
    this.setState({
      frame: frame
    });
  };

  setEncounter = encounter => {
    this.setState({
      encounter: encounter
    });
  };

  setNextFrame = currentFrame => {
    this.setState({
      frame: currentFrame + 1
    });
  };

  setPathComplete = path => {
    if (path === "Retirement") {
      this.setState({
        retirementComplete: true
      });
    } else {
      this.setState({
        educationComplete: true
      });
    }
  };

  setGrowth = status => {
    this.setState({
      growth: status
    });
  };

  setTransition = status => {
    this.setState({
      transition: status
    });
  };

  // Records any offsite links visited

  // setOffsiteProgress = destination => {
  //   this.setState(({ offsite }) => ({
  //     offsite: [...offsite, destination]
  //   }));
  // };

  // Called at initial app load
  // If the user has a marketing ID appended to their URL, it gets logged in state
  // Otherwise, if no ID is found, it prints an error message to state

  setMarketingId = id => {
    if (id) {
      this.setState({
        marketingId: id
      });
    } else {
      this.setState({
        marketingId: "No ID found"
      });
    }
  };

  // Creates a new database key for this quiz session
  // All progress is written to this key as the user completes the quiz

  setDatabaseRef = () => {
    const newRef = firebase
      .database()
      .ref()
      .push();
    this.setState({
      databaseKey: newRef.key
    });
  };

  updateClickstream = (name, action, cta = false) => {
    let lastClick = action ? action : this.state.lastClick;
    const timeStr = moment().format("YYYY-MM-DDTHH:mm:ss");

    let clickKey = "choice";
    if ((name && name.startsWith("http")) || cta === true) {
      clickKey = "CTA";
    }
    let click = {
      [clickKey]: lastClick || "Article page: Play Now",
      time: timeStr
    };
    let destination;
    if (name) {
      destination = {
        page: name,
        time: timeStr
      };
    }

    this.setState(({ clickstream }) => {
      let newClickstream;
      if (destination) {
        newClickstream = [...clickstream, click, destination];
      } else {
        newClickstream = [...clickstream, click];
      }
      return {
        clickstream: newClickstream
      };
    });
  };

  updateLastClick = name => {
    this.setState({
      lastClick: name,
      clickUrl: window.location.href
    });
  };

  // Writes the state object to firebase under a new key
  // Note that, even if a user retakes the quiz with the same marketing ID, it will generate a new entry in Firebase

  // IMPORTANT!!!!
  // REPLACE FIREBASE.JS BEFORE LAUNCH! The attached database is insecure and is meant for testing purposes ONLY!

  pushProgress(reference) {
    const progress = {
      marketingId: this.state.marketingId,
      character: this.state.character,
      clickstream: this.state.clickstream
      // otherProgress: {
      //   offsite: this.state.offsite
      // }
    };

    firebase
      .database()
      .ref(reference)
      .update(progress);
  }

  componentDidMount() {
    let urlParams = new URLSearchParams(window.location.search);
    this.setMarketingId(urlParams.get("mpid"));
    this.setDatabaseRef();
    document.title = "On Your Way";
  }

  componentDidUpdate() {
    this.pushProgress("/onYourWay/" + this.state.databaseKey);
  }

  render() {
    return (
      <BrowserRouter>
        <AppContainer frame={this.state.frame}>
          <Switch>
            <Route
              path="/"
              exact
              render={() => (
                <Landing
                  updateClickstream={this.updateClickstream}
                  updateLastClick={this.updateLastClick}
                />
              )}
            />

            <Route
              path="/characters"
              exact
              render={() => (
                <Characters
                  setCharacter={this.setCharacter}
                  updateClickstream={this.updateClickstream}
                  updateLastClick={this.updateLastClick}
                />
              )}
            />

            <Route
              path="/explore"
              exact
              render={() => (
                <Explore
                  retirementComplete={this.state.retirementComplete}
                  educationComplete={this.state.educationComplete}
                  setPath={this.setPath}
                  setGrowth={this.setGrowth}
                  updateClickstream={this.updateClickstream}
                  updateLastClick={this.updateLastClick}
                />
              )}
            />

            <Route
              path="/retirement"
              exact
              render={() => (
                <Path
                  data={data.retirement}
                  character={this.state.character}
                  path={this.state.path}
                  frame={this.state.frame}
                  retirementComplete={this.state.retirementComplete}
                  educationComplete={this.state.educationComplete}
                  growth={this.state.growth}
                  transition={this.state.transition}
                  setPath={this.setPath}
                  setFrame={this.setFrame}
                  setNextFrame={this.setNextFrame}
                  setPathComplete={this.setPathComplete}
                  setGrowth={this.setGrowth}
                  setTransition={this.setTransition}
                  updateClickstream={this.updateClickstream}
                  updateLastClick={this.updateLastClick}
                />
              )}
            />

            <Route
              path="/education"
              exact
              render={() => (
                <Path
                  data={data.education}
                  character={this.state.character}
                  path={this.state.path}
                  frame={this.state.frame}
                  retirementComplete={this.state.retirementComplete}
                  educationComplete={this.state.educationComplete}
                  growth={this.state.growth}
                  transition={this.state.transition}
                  setPath={this.setPath}
                  setFrame={this.setFrame}
                  setNextFrame={this.setNextFrame}
                  setPathComplete={this.setPathComplete}
                  setGrowth={this.setGrowth}
                  setTransition={this.setTransition}
                  updateClickstream={this.updateClickstream}
                  updateLastClick={this.updateLastClick}
                />
              )}
            />

            <Route
              path="/congrats"
              exact
              render={() => (
                <Congrats
                  updateClickstream={this.updateClickstream}
                  updateLastClick={this.updateLastClick}
                />
              )}
            />

            <Route
              path="/contact"
              exact
              render={() => (
                <Contact
                  updateClickstream={this.updateClickstream}
                  updateLastClick={this.updateLastClick}
                />
              )}
            />

            <Route
              path="/legal"
              exact
              render={() => (
                <Legal
                  updateClickstream={this.updateClickstream}
                  updateLastClick={this.updateLastClick}
                />
              )}
            />
          </Switch>
        </AppContainer>
      </BrowserRouter>
    );
  }
}

export default App;
