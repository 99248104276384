import React from "react";
import styled from "styled-components/macro";
import { css } from "styled-components";
import { DEVICE, PATTERN, KEYFRAMES } from "../constants";

const EncounterContainer = styled.div`
  display: none;

  @media screen and (min-width: ${DEVICE.PHONE_LANDSCAPE}) {
    display: inline-block;
    position: absolute;
    bottom: 0;
    right: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;
  }
`;

const EncounterSpan = styled.span`
  display: inline-block;
  position: absolute;
  width: ${props => (props.width * 100) / 760}vh;
  height: ${props => (props.height * 100) / 760}vh;
  right: ${props => (props.right * 100) / 760}vh;
  bottom: ${props => (props.bottom * 100) / 760}vh;
  opacity: 0;
  transform: translateY(-100vh);
  will-change: opacity, transform;
  transition: opacity 0.5s ease-in-out, transform 0.5s ease-in-out;
  text-align: center;

  ${props =>
    props.transition === null &&
    css`
      opacity: 0;
      transform: translateY(-100vh);
    `}

  ${props =>
    props.transition === "isEntering" &&
    css`
      opacity: 0;
      transform: translateY(-100vh);
    `}

    ${props =>
      props.transition === "isEntered" &&
      css`
        opacity: 1;
        transform: translateY(0);
      `}

  ${props =>
    props.transition === "isExited" &&
    css`
      opacity: 0;
      transform: translateY(100vh);
    `}

  ${props =>
    props.transition === "isExiting" &&
    css`
      opacity: 0;
      transform: translateY(100vh);
    `}
    

  ${props =>
    props.frame === 2 &&
    props.path === "Education" &&
    css`
      &:nth-child(4n + 1) {
        z-index: 1;
      }
      &:nth-child(4n + 2) {
        z-index: 1;
      }
    `}

  div {
    position: relative;
    display: inline-block;
    width: 100%;
    height: 100%;

    &#object-2-1 {
      overflow: hidden;
    }
  }

  img {
    width: auto;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;

    ${props =>
      props.frame === 0 &&
      props.path === "Retirement" &&
      props.transition === "isEntered" &&
      css`
        &#object-0-0-1 {
          opacity: 0;
          animation: ${KEYFRAMES.FADEIN} 0.25s linear 0.75s forwards;
        }

        &#object-0-1-1 {
          opacity: 0;
          animation: ${KEYFRAMES.FADEIN} 0.25s linear 1.4s forwards;
        }
      `}

      ${props =>
        props.frame === 0 &&
        props.path === "Education" &&
        props.transition === "isEntered" &&
        css`
          &#object-0-0-1 {
            opacity: 0;
            animation: ${KEYFRAMES.FADEIN} 0.25s linear 0.75s forwards;
          }

          &#object-0-1-1 {
            opacity: 0;
            animation: ${KEYFRAMES.FADEIN} 0.25s linear 1.4s forwards;
          }

          &#object-0-2-1 {
            opacity: 0;
            animation: ${KEYFRAMES.FADEIN} 0.25s linear 2.05s forwards;
          }
        `}

    ${props =>
      props.frame === 1 &&
      props.path === "Retirement" &&
      props.transition === "isEntered" &&
      css`
        &#object-1-0-1 {
          opacity: 0;
          animation: ${KEYFRAMES.FADEIN} 0.25s linear 0.75s forwards;
        }

        &#object-1-0-2 {
          opacity: 0;
          animation: ${KEYFRAMES.FADEIN} 0.25s linear 1.4s forwards;
        }

        &#object-1-0-3 {
          opacity: 0;
          animation: ${KEYFRAMES.FADEIN} 0.25s linear 2.05s forwards;
        }

        &#object-1-1-1 {
          opacity: 0;
          animation: ${KEYFRAMES.FADEIN} 0.25s linear 2.7s forwards;
        }

        &#object-1-1-2 {
          opacity: 0;
          animation: ${KEYFRAMES.FADEIN} 0.25s linear 3.35s forwards;
        }
      `}

      ${props =>
        props.frame === 1 &&
        props.path === "Education" &&
        props.transition === "isEntered" &&
        css`
          &#object-1-0-1 {
            opacity: 0;
            animation: ${KEYFRAMES.FADEIN} 0.25s linear 0.75s forwards;
          }

          &#object-1-1-1 {
            opacity: 0;
            animation: ${KEYFRAMES.FADEIN} 0.25s linear 1.4s forwards;
          }

          &#object-1-2-1 {
            opacity: 0;
            animation: ${KEYFRAMES.FADEIN} 0.25s linear 2.05s forwards;
          }

          &#object-1-3-1 {
            opacity: 0;
            animation: ${KEYFRAMES.FADEIN} 0.25s linear 2.7s forwards;
          }
        `}

    ${props =>
      props.frame === 2 &&
      props.growth === 0 &&
      props.transition === "isEntered" &&
      css`
        &#object-2-0-0 {
          opacity: 1;
        }

        &#object-2-2-0 {
          opacity: 0;
          animation: ${KEYFRAMES.FADEIN} 0.25s linear 0.5s forwards;
        }
      `}

      ${props =>
        props.frame === 2 &&
        css`
          &#object-2-1-0 {
            z-index: 30;
          }
          &#object-2-1-1 {
            z-index: 20;
          }
          &#object-2-1-2 {
            z-index: 10;
          }
        `}

    ${props =>
      props.frame === 2 &&
      props.growth === 0 &&
      css`
        &#object-2-1-0 {
          opacity: 0;
        }
        &#object-2-1-1 {
          opacity: 0;
        }
        &#object-2-1-2 {
          opacity: 0;
        }
      `}

    ${props =>
      props.frame === 2 &&
      props.growth === 1 &&
      css`
        &#object-2-0-0 {
          opacity: 0;
          animation: ${KEYFRAMES.HIDE} 0s linear forwards;
        }

        &#object-2-1-0 {
          opacity: 0;
          transform: translateY(80%);
          animation: ${KEYFRAMES.GROW} 0.25s linear forwards;
        }

        &#object-2-1-1 {
          opacity: 0;
          transform: translateY(80%);
          animation: ${KEYFRAMES.GROW} 0.25s linear 0.5s forwards;
        }

        &#object-2-1-2 {
          opacity: 0;
          transform: translateY(80%);
          animation: ${KEYFRAMES.GROW} 0.25s linear 1s forwards;
        }
      `}
    
    ${props =>
      props.frame === 4 &&
      props.transition === "isEntered" &&
      css`
        &#object-4-2-0 {
          opacity: 0;
          transform: translateY(-200%);
          animation: ${KEYFRAMES.DROPBLOCK} 0.45s ease-in 2.05s forwards;
          will-change: opacity, transform;
          width: 100%;
          height: auto;
        }
      `}
  }

`;

const EncounterInteract = styled.span`
  display: inline-block;
  position: absolute;
  width: ${props => (props.width * 100) / 760}vh;
  height: ${props => (props.height * 100) / 760}vh;
  right: ${props => (props.right * 100) / 760}vh;
  bottom: ${props => (props.bottom * 100) / 760}vh;
  opacity: 0;
  transform: translateY(-100vh);
  will-change: opacity, transform;
  transition: opacity 0.5s ease-in-out, transform 0.5s ease-in-out;
  text-align: center;
  z-index: 40;

  ${props =>
    props.transition === null &&
    css`
      opacity: 0;
      transform: translateY(-100vh);
    `}

  ${props =>
    props.transition === "isEntering" &&
    css`
      opacity: 0;
      transform: translateY(-100vh);
    `}

    ${props =>
      props.transition === "isEntered" &&
      css`
        opacity: 1;
        transform: translateY(0);
      `}

  ${props =>
    props.transition === "isExited" &&
    css`
      opacity: 0;
      transform: translateY(100vh);
    `}

  ${props =>
    props.transition === "isExiting" &&
    css`
      opacity: 0;
      transform: translateY(100vh);
    `}

  div {
    position: relative;
    display: inline-block;
    width: 100%;
    height: 100%;
  }

  img {
    width: auto;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;

    ${props =>
      props.frame === 2 &&
      props.growth === 1 &&
      css`
        &#object-2-2-0 {
          opacity: 0;
        }
      `}

  }

  .dot {
    position: relative;
    display: inline-block;
    background: #dbaa35;
    width: 3vh;
    height: 3vh;
    border-radius: 3vh;
    margin-top: 1.5em;
    opacity: 0;
    animation: ${KEYFRAMES.FADEIN} 0.25s linear 1s forwards;
    cursor: pointer;
    overflow: visible;

    .dot-pulse  {
      ${PATTERN.PULSE}
      position: absolute;
      top:0;
      left: 0;
      display: inline-block;
      background: #dbaa35;
      width: 3vh;
      height: 3vh;
      border-radius: 3vh;
      opacity: 0.5;
      overflow: visible;
    }

    ${props =>
      props.frame === 2 &&
      props.growth &&
      css`
        display: none;
      `}
  }

`;

const EncounterLink = styled.a`
  display: inline-block;
  position: absolute;
  width: ${props => (props.width * 100) / 760}vh;
  height: ${props => (props.height * 100) / 760}vh;
  right: ${props => (props.right * 100) / 760}vh;
  bottom: ${props => (props.bottom * 100) / 760}vh;
  transition: all 0.5s ease-in-out;
  will-change: transform, opacity;

  ${props =>
    props.transition === "isEntering" &&
    css`
      opacity: 0;
      transform: translateY(-100vh);
    `}

    ${props =>
      props.transition === "isEntered" &&
      css`
        opacity: 1;
        transform: translateY(0);
      `}

  ${props =>
    props.transition === "isExited" &&
    css`
      opacity: 1;
      transform: translateY(-100vh);
    `}

  ${props =>
    props.transition === "isExiting" &&
    css`
      opacity: 0;
      transform: translateY(100vh);
    `}

  div {
    position: relative;
    display: inline-block;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
  }

  img {
    width: auto;
    height: 100%;
    position: absolute;

    ${props =>
      props.frame === 3 &&
      props.transition === "isEntered" &&
      css`
        &#object-3-0-1 {
          opacity: 0;
          animation: ${KEYFRAMES.FADEIN} 0.25s linear 0.75s forwards;
        }

        &#object-3-0-2 {
          opacity: 0;
          animation: ${KEYFRAMES.FADEIN} 0.25s linear 0.75s forwards;

          &:hover {
            animation: ${KEYFRAMES.FADEIN} 0.25s linear 0.75s forwards,
              ${KEYFRAMES.POLYGON} 0.25s infinite alternate;
          }
        }

        &#object-3-1-1 {
          opacity: 0;
          animation: ${KEYFRAMES.FADEIN} 0.25s linear 1.4s forwards;
        }

        &#object-3-1-2 {
          opacity: 0;
          animation: ${KEYFRAMES.FADEIN} 0.25s linear 1.4s forwards;

          &:hover {
            animation: ${KEYFRAMES.FADEIN} 0.25s linear 1.4s forwards,
              ${KEYFRAMES.POLYGON} 0.25s infinite alternate;
          }
        }

        &#object-3-2-1 {
          opacity: 0;
          animation: ${KEYFRAMES.FADEIN} 0.25s linear 2.05s forwards;
        }

        &#object-3-2-2 {
          opacity: 0;
          animation: ${KEYFRAMES.FADEIN} 0.25s linear 2.05s forwards;

          &:hover {
            animation: ${KEYFRAMES.FADEIN} 0.25s linear 2.05s forwards,
              ${KEYFRAMES.POLYGON} 0.25s infinite alternate;
          }
        }
      `}

    ${props =>
      props.frame === 4 &&
      props.transition === "isEntered" &&
      css`
        &#object-4-0-1 {
          opacity: 0;
          animation: ${KEYFRAMES.FADEIN} 0.25s linear 0.75s forwards;
        }

        &#object-4-0-2 {
          opacity: 0;
          animation: ${KEYFRAMES.FADEIN} 0.25s linear 0.75s forwards;

          &:hover {
            animation: ${KEYFRAMES.FADEIN} 0.25s linear 0.75s forwards,
              ${KEYFRAMES.POLYGON} 0.25s infinite alternate;
          }
        }

        &#object-4-1-1 {
          opacity: 0;
          animation: ${KEYFRAMES.FADEIN} 0.25s linear 1.4s forwards;
        }

        &#object-4-1-2 {
          opacity: 0;
          animation: ${KEYFRAMES.FADEIN} 0.25s linear 1.4s forwards;

          &:hover {
            animation: ${KEYFRAMES.FADEIN} 0.25s linear 1.4s forwards,
              ${KEYFRAMES.POLYGON} 0.25s infinite alternate;
          }
        }
      `}
  }
`;

class Encounters extends React.Component {
  componentDidMount() {
    this.props.setTransition("isEntering");
    setTimeout(() => {
      this.props.setTransition("isEntered");
    }, 1000);

    // this.props.setTransition("isEntered");
    // this.props.checkProgress();
    // this.props.updateClickstream(
    //   this.props.path === "Retirement"
    //     ? data.retirement.frame[this.props.frame].headline
    //     : data.education.frame[this.props.frame].headline
    // );
  }

  componentDidUpdate() {
    // setTimeout(() => {
    //   this.props.setTransition("isExisting");
    // }, 500);
    //   if (this.props.canAdvance !== prevState.canAdvance) {
    //     this.props.checkProgress();
    //   }
  }

  render() {
    return (
      <EncounterContainer>
        {this.props.data.frame[this.props.frame].encounter.map(
          (object, index) =>
            object.url.length > 0 ? (
              object.url.length > 1 ? (
                <EncounterLink
                  href={object.url}
                  target="_blank"
                  aria-label={object.title}
                  key={index}
                  width={object.width}
                  height={object.height}
                  right={object.right}
                  bottom={object.bottom}
                  path={this.props.path}
                  frame={this.props.frame}
                  alt={object.alt}
                  transition={this.props.transition}
                  onClick={() => {
                    const action = `${object.asset}`;
                    this.props.updateClickstream(object.url, action, true);
                  }}
                >
                  <div id={object.id}>
                    <img
                      id={object.id + "-0"}
                      src={require("../img/" + object.source[0])}
                      alt="object that slide in"
                    />
                    <img
                      id={object.id + "-1"}
                      src={require("../img/" + object.source[1])}
                      alt="object that slide in"
                    />
                    <img
                      id={object.id + "-2"}
                      src={require("../img/" + object.source[2])}
                      alt="bouncing red caret"
                    />
                  </div>
                </EncounterLink>
              ) : (
                <EncounterInteract
                  key={index}
                  width={object.width}
                  height={object.height}
                  right={object.right}
                  bottom={object.bottom}
                  path={this.props.path}
                  frame={this.props.frame}
                  growth={this.props.growth}
                  transition={this.props.transition}
                >
                  <div id={object.id}>
                    <img
                      id={object.id + "-0"}
                      src={require("../img/" + object.source[0])}
                      alt="word bubble"
                    />
                  </div>

                  <span
                    className="dot"
                    growth={this.props.growth}
                    onClick={() => {
                      const action = `${this.props.path}: ${
                        this.props.data.frame[this.props.frame].choice
                      }`;
                      this.props.setGrowth(1);
                      this.props.updateClickstream(null, action);
                    }}
                  >
                    <span className="dot-pulse" />
                  </span>
                </EncounterInteract>
              )
            ) : (
              <EncounterSpan
                key={index}
                width={object.width}
                height={object.height}
                right={object.right}
                bottom={object.bottom}
                path={this.props.path}
                frame={this.props.frame}
                growth={this.props.growth}
                transition={this.props.transition}
              >
                <div id={object.id}>
                  {object.source.map((nestedObject, index) => (
                    <img
                      key={index}
                      id={object.id + "-" + index}
                      src={require("../img/" + nestedObject)}
                      alt="object that slide in"
                    />
                  ))}
                </div>
              </EncounterSpan>
            )
        )}
      </EncounterContainer>
    );
  }
}

export default Encounters;
