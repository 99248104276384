import React from "react";
import styled from "styled-components/macro";
import { SPRITE } from "../constants.js";

const HeaderContainer = styled.nav`
  display: flex;
  justify-content: space-between;
  width: 100%;
  flex-grow: 0;
  height: 42px;
  margin-bottom: 24px;
`;

const Logo = styled.span`
  ${SPRITE.LOGO} z-index: 9999;
`;

const Close = styled.a`
  ${SPRITE.CLOSE} z-index: 9999;
`;

class Header extends React.Component {
  render() {
    return (
      <HeaderContainer>
        <Logo />
        <Close
          href="http://ubs.com"
          onClick={() => {
            this.props.updateClickstream("http://ubs.com", 'Close Button');
          }}
        >
          Close
        </Close>
      </HeaderContainer>
    );
  }
}

export default Header;
