import { css, keyframes } from "styled-components";

import Character1 from "./img/female-blue.png";
import Character2 from "./img/male-red.png";
import Character3 from "./img/male-yellow.png";
import Character4 from "./img/female-red.png";

import SmallCharacter1 from "./img/female-blue-small.png";
import SmallCharacter2 from "./img/male-red-small.png";
import SmallCharacter3 from "./img/male-yellow-small.png";
import SmallCharacter4 from "./img/female-red-small.png";

import RetirementSpot from "./img/retirement-spot-clear.svg";
import EducationSpot from "./img/education-spot-clear.svg";

import Sprite from "./img/sprite.svg";
import Cheveron from "./img/cheveron.svg";

import MobileAnimation from "./img/mobile-animation.gif";

export const CHARACTERS = [Character1, Character2, Character3, Character4];
export const SMALLCHARACTERS = [
  SmallCharacter1,
  SmallCharacter2,
  SmallCharacter3,
  SmallCharacter4
];

export const SPOTS = [RetirementSpot, EducationSpot];

export const SPRITE = {
  CHEVRON: css`
    content: "";
    display: inline-block;
    position: absolute;
    width: 10px;
    height: 1em;
    background: url(${Cheveron}) no-repeat center center;
    margin: 0 0 0 0.2em;
    transition: all 0.3s ease;
    transform: translate(75%, 0);

    @media all and (-ms-high-contrast: none) {
      margin: 0 0 -0.1em 0.2em; /* IE10 */

      *::-ms-backdrop {
        margin: 0 0 -0.1em 0.2em;
      } /* IE11 */
    }
  `,
  CLOSE: css`
    display: inline-block;
    text-indent: -9999999px;
    width: 26px;
    height: 26px;
    background: url(${Sprite}) no-repeat -116px -0px;
  `,
  LOGO: css`
    display: inline-block;
    text-indent: -9999999px;
    width: 115px;
    height: 42px;
    background: url(${Sprite}) no-repeat -0px -0px;
  `,
  CHAT: css`
    display: inline-block;
    text-indent: -9999999px;
    width: 110px;
    height: 96px;
    background: url(${Sprite}) no-repeat -0px -44px;
  `,
  TROPHY: css`
    display: inline-block;
    text-indent: -9999999px;
    width: 66px;
    height: 100px;
    background: url(${Sprite}) no-repeat -112px -44px;
  `,
  CALENDAR: css`
    display: inline-block;
    text-indent: -9999999px;
    width: 94px;
    height: 106px;
    background: url(${Sprite}) no-repeat -0px -262px;
  `,
  PHONE: css`
    display: inline-block;
    text-indent: -9999999px;
    width: 100px;
    height: 84px;
    background: url(${Sprite}) no-repeat -94px -262px;
  `
};

export const DEVICE = {
  MONITOR: "1440px",
  LAPTOP: "1280px",
  TABLET_LANDSCAPE: "1024px",
  TABLET_PORTRAIT: "768px",
  PHONE_LANDSCAPE: "480px"
};

export const SPACING = {
  SMALL: "8px",
  MEDIUM: "16px",
  LARGE: "24px",
  XLARGE: "48px",
  XXLARGE: "64px"
};

export const COLOR = {
  PRIMARY: "#000",
  SECONDARY: "#4d4d4d",
  TERTIARY: "#EDEDED",
  ACCENT_1: "#90433b",
  ACCENT_2: "#8AA75D"
};

export const FONT = {
  FAMILY: {
    PRIMARY:
      "'FrutigerLTPro-Light', Courier, 'Lucida Grande', 'Lucida Sans Unicode', 'Lucida Sans', Geneva, Verdana, sans-serif;",
    ACCENT:
      "'FrutigerLTPro-LightCn', Courier, 'Lucida Grande', 'Lucida Sans Unicode', 'Lucida Sans', Geneva, Verdana, sans-serif;"
  }
};

export const ROTATE = {
  MESSAGE: css`
    display: inline-block;
    width: 100%;
    height: calc(100% - 42px - 24px);

    @media screen and (min-width: ${DEVICE.PHONE_LANDSCAPE}) {
      display: none;
    }
  `,
  IMAGE: css`
    display: inline-block;
    width: 100%;
    height: 60%;
    background: url(${MobileAnimation}) no-repeat -0px -0px;
    background-size: contain;
  `
};

export const KEYFRAMES = {
  FADEIN: keyframes`
        0% { opacity: 0 }
        50% { opacity: 0 }
        100% { opacity: 1}
      `,
  GROW: keyframes`
        0% { transform: translateY(80%); opacity: 0; }
        100% {transform: translateY(0%); opacity: 1; }
      `,
  HIDE: keyframes`
        0% { opacity: 1 }
        100% { opacity: 0}
      `,
  FROMRIGHT: keyframes`
        0% { transform: translateX(200%) scaleX(-1); opacity: 0; }
        50% { transform: translateX(200%) scaleX(-1); opacity: 0; }
        100% { transform: translateX(0%) scaleX(-1); opacity: 1; }
      `,
  FROMLEFT: keyframes`
        0% { transform: translateX(-200%) scaleX(1); opacity: 0; }
        50% { transform: translateX(-200%) scaleX(1); opacity: 0; }
        100% { transform: translateX(0%) scaleX(1); opacity: 1; }
      `,
  DROPBLOCK: keyframes`
        0% { transform: translateY(-200%); opacity: 0; }
        25% { transform: translateY(-200%); opacity: 1; }
        100% { transform: translateX(0%);opacity: 1; }
      `,
  PULSE: keyframes`
        0% {
            transform: scale(1);
            opacity: 1;
        }

        100% {
            transform: scale(3);
            opacity: 0;
        }
      `,

  POLYGON: keyframes`
        0% {
            transform: translateX(0);
        }
        100% {
            transform: translateX(7px);
        }
      `,
  CHEVRON: keyframes`
          0% {
              transform: translate(75%, 0);
          }
          100% {
              transform: translate(175%, 0);
          }
        `,
  CHEVRON_PHONE_LANDSCAPE: keyframes`
          0% {
              transform: translate(75%, 0);
          }
          100% {
              transform: translate(175%, 0);
          }
        `,
  CHEVRON_TABLET_PORTRAIT: keyframes`
          0% {
              transform: translate(85%, 0);
          }
          100% {
              transform: translate(185%, 0);
          }
        `,
  CHEVRON_TABLET_LANDSCAPE: keyframes`
          0% {
              transform: translate(95%, 0);
          }
          100% {
              transform: translate(195%, 0);
          }
        `,
  CHEVRON_LAPTOP: keyframes`
          0% {
              transform: translate(105%, 0);
          }
          100% {
              transform: translate(205%, 0);
          }
        `
};

export const FONT_STYLE = {
  DISPLAY_HEADLINE: css`
    font-family: ${FONT.FAMILY.PRIMARY};
    font-size: 2.4em;
    line-height: 1.2em;
    color: ${COLOR.PRIMARY};
    font-weight: normal;
    margin: 0;
  `,
  DISPLAY_HEADLINE_EMPHASIS: css`
    font-family: ${FONT.FAMILY.PRIMARY};
    font-size: 2em;
    line-height: 0.75em;
    color: ${COLOR.ACCENT_1};
    display: block;
    margin: 0;
  `,

  DISPLAY_BODY: css`
    font-family: ${FONT.FAMILY.PRIMARY};
    font-size: 1.2em;
    line-height: 1.4em;
    color: ${COLOR.SECONDARY};
  `,

  DISPLAY_CAPTION: css`
    font-family: ${FONT.FAMILY.PRIMARY};
    font-size: 0.7em;
    line-height: 1.35em;
  `,

  QUIZ_HEADLINE: css`
    font-family: ${FONT.FAMILY.PRIMARY};
    font-size: 1.8em;
    line-height: 1.1em;
    color: ${COLOR.PRIMARY};
    font-weight: normal;
    padding-bottom: 0.25em;
    width: 100%;
    margin: 0;
    line-height: 1.25em;
  `,

  QUIZ_BODY: css`
    font-family: ${FONT.FAMILY.PRIMARY};
    font-size: 1em;
    line-height: 1.4em;
    color: ${COLOR.SECONDARY};
  `,

  HEADLINE: css`
    font-family: ${FONT.FAMILY.PRIMARY};
    font-size: 1.6em;
    line-height: 1em;
    color: ${COLOR.PRIMARY};
    font-weight: normal;
  `,

  BODY: css`
    font-family: ${FONT.FAMILY.PRIMARY};
    font-size: 0.85em;
    line-height: 1.25em;
    color: ${COLOR.SECONDARY};
  `,

  DETAIL: css`
    font-family: ${FONT.FAMILY.PRIMARY};
    font-size: 0.7em;
    line-height: 1.4em;
    color: ${COLOR.SECONDARY};
  `,

  OPTION: css`
    font-family: ${FONT.FAMILY.ACCENT};
    font-size: 1.2em;
    line-height: 1.2em;
    color: ${COLOR.SECONDARY};
    text-decoration: none;
  `,

  BUTTON: css`
    font-family: ${FONT.FAMILY.ACCENT};
    font-size: 1.2em;
    line-height: 1.2em;
    color: ${COLOR.PRIMARY};
    text-decoration: none;
  `
};

export const PATTERN = {
  CHEVRON: css`
    ${SPRITE.CHEVRON}

    @media screen and (min-width: ${DEVICE.PHONE_LANDSCAPE}){
        transform: translate(75%, 0);
    }

    @media screen and (min-width: ${DEVICE.TABLET_PORTRAIT}){
        transform: translate(75%, 0);
    }

    @media screen and (min-width: ${DEVICE.TABLET_LANDSCAPE}){
        transform: translate(75%, 0);

    }

    @media screen and (min-width: ${DEVICE.LAPTOP}){
        transform: translate(75%, 0);
    }
  `,
  FLEXCONTAINER: css`
    height: 100%;
    width: 100%;
    display: block;
    overflow-y: auto;
    overflow-x: hidden;
    padding: ${SPACING.MEDIUM};
    display: flex;
    position: relative;
    flex-direction: column;

    @media screen and (min-width: ${DEVICE.TABLET_LANDSCAPE}) {
      padding: ${SPACING.LARGE};
    }
  `,
  FADEIN: css`
    animation: ${KEYFRAMES.FADEIN} 1s ease-out;
  `,
  FROMRIGHT: css`
    animation: ${KEYFRAMES.FROMRIGHT} 1s ease-out;
  `,
  FROMLEFT: css`
    animation: ${KEYFRAMES.FROMLEFT} 1s ease-out;
  `,
  FROMTOP: css`
    animation: ${KEYFRAMES.FROMTOP} 1s ease-in-out 0.5s;
  `,
  PULSE: css`
    animation: ${KEYFRAMES.PULSE} 2s ease-out infinite;
  `
};
