import React from "react";
import styled from "styled-components/macro";
import { css } from "styled-components";
import { Link } from "react-router-dom";
import {
  FONT_STYLE,
  SPACING,
  SPOTS,
  PATTERN,
  DEVICE,
  ROTATE
} from "../constants";
import data from "../content.json";
import Header from "./Header";
import Complete from "../img/complete.svg";

const MobileMessage = styled.div`
  ${ROTATE.MESSAGE};
`;

const MobileHeadline = styled.h1`
  display: inline-block;
  ${FONT_STYLE.QUIZ_HEADLINE}
`;

const MobileBody = styled.p`
  display: inline-block;
  ${FONT_STYLE.QUIZ_BODY}
  margin: 0.5em 0 2em;
`;

const MobileAnimation = styled.div`
  ${ROTATE.IMAGE};
`;

const PageContainer = styled.div`
  ${PATTERN.FLEXCONTAINER}
  align-content: flex-start;
`;

const Headline = styled.h1`
  display: none;

  @media screen and (min-width: ${DEVICE.PHONE_LANDSCAPE}) {
    display: inline-block;
    ${FONT_STYLE.QUIZ_HEADLINE}
    ${PATTERN.FADEIN}
  }
`;

const Paragraph = styled.p`
  display: none;

  @media screen and (min-width: ${DEVICE.PHONE_LANDSCAPE}) {
    display: inline-block;
    ${FONT_STYLE.QUIZ_BODY}
    margin-bottom: ${SPACING.LARGE};
    ${PATTERN.FADEIN}
    width: 100%;
    margin: 0 0 20px;
  }

  @media screen and (min-width: ${DEVICE.TABLET_LANDSCAPE}) {
    margin: 0 0 24px;
  }

  @media screen and (min-width: ${DEVICE.LAPTOP}) {
    margin: 0 0 48px;
  }
`;

const OptionContainer = styled.div`
  display: none;

  @media screen and (min-width: ${DEVICE.PHONE_LANDSCAPE}) {
    display: flex;
    align-items: flex-start;
    ${PATTERN.FADEIN}
    width: 100%;
  }

  @media screen and (min-width: ${DEVICE.TABLET_PORTRAIT}) {
    align-items: center;
    flex-grow: 2;
  }
`;

const Option = styled(({ educationComplete, retirementComplete, ...rest }) => (
  <Link {...rest} />
))`
  display: inline-block;
  flex-basis: 50%;
  height: auto;
  transition: transform 0.3s ease;
  height: calc(20vh + 2.25em);
  margin-bottom: 2.25em;
  position: relative;

  @media screen and (min-width: ${DEVICE.TABLET_PORTRAIT}) {
    height: calc(35vh + 2.25em);
  }


  @media screen and (min-width: ${DEVICE.LAPTOP}) {
    height: calc(39vh + 2.25em);z
  }

  ${props =>
    props.path === data.explore.paths[0] &&
    css`
      background: url(${SPOTS[0]}) no-repeat center center;
      background-size: auto 100%;
    `}

  ${props =>
    props.path === data.explore.paths[0] &&
    props.retirementComplete &&
    css`
      opacity: 0.5;
    `}

  ${props =>
    props.path === data.explore.paths[1] &&
    css`
      background: url(${SPOTS[1]}) no-repeat center center;
      background-size: auto 100%;
    `}

  ${props =>
    props.path === data.explore.paths[1] &&
    props.educationComplete &&
    css`
      opacity: 0.5;
    `}

  &:hover {
    transform: scale(1.1);
  }

  span {
    ${FONT_STYLE.QUIZ_BODY};
    display: inline-block;
    width: 100%;
    text-align: center;
    position: absolute;
    bottom: -2.25em;
    left: 0;

    &::after {
      opacity: 0;
      position: absolute;
      margin: -0.5em 0 0 0.75em;
      display: inline-block;
      background: url(${Complete}) no-repeat center center;
      content: "";
      width: 2em;
      height 2em;
    }

    ${props =>
      props.path === data.explore.paths[0] &&
      props.retirementComplete &&
      css`
        &::after {
          opacity: 1;
        }
      `}

    ${props =>
      props.path === data.explore.paths[1] &&
      props.educationComplete &&
      css`
        &::after {
          opacity: 1;
        }
      `}
  }
`;

class Explore extends React.Component {
  componentDidMount() {
    !this.props.retirementComplete &&
      !this.props.educationComplete &&
      this.props.updateClickstream(data.explore.headline[0]);
    this.props.retirementComplete &&
      this.props.updateClickstream(data.explore.headline[1]);
    this.props.educationComplete &&
      this.props.updateClickstream(data.explore.headline[2]);
    this.props.setGrowth(0);
  }

  render() {
    return (
      <PageContainer>
        <Header updateClickstream={this.props.updateClickstream} />
        <MobileMessage>
          <MobileHeadline>Please rotate your device.</MobileHeadline>
          <MobileBody>
            This experience is optimized for landscape mode.
          </MobileBody>
          <MobileAnimation />
        </MobileMessage>
        <Headline>
          {!this.props.retirementComplete && !this.props.educationComplete
            ? data.explore.headline[0]
            : this.props.retirementComplete && !this.props.educationComplete
            ? data.explore.headline[1]
            : data.explore.headline[2]}
        </Headline>
        <Paragraph>
          {!this.props.retirementComplete && !this.props.educationComplete
            ? data.explore.body[0]
            : this.props.retirementComplete && !this.props.educationComplete
            ? data.explore.body[1]
            : data.explore.body[2]}
        </Paragraph>
        <OptionContainer>
          <Option
            to="/retirement"
            path={data.explore.paths[0]}
            retirementComplete={this.props.retirementComplete}
            onClick={() => {
              this.props.setPath(data.explore.paths[0]);
              this.props.updateLastClick(data.explore.paths[0]);
            }}
          >
            <span>{data.explore.paths[0]}</span>
          </Option>
          <Option
            to="/education"
            path={data.explore.paths[1]}
            educationComplete={this.props.educationComplete}
            onClick={() => {
              this.props.setPath(data.explore.paths[1]);
              this.props.updateLastClick(data.explore.paths[1]);
            }}
          >
            <span>{data.explore.paths[1]}</span>
          </Option>
        </OptionContainer>
      </PageContainer>
    );
  }
}

export default Explore;
