import React from "react";
import styled from "styled-components/macro";
import { FONT_STYLE, PATTERN, DEVICE } from "../constants";
import data from "../content.json";
import parse from "html-react-parser";

const CopyContainer = styled.div`
  display: none;

  @media screen and (min-width: ${DEVICE.PHONE_LANDSCAPE}) {
    ${PATTERN.FLEXCONTAINER}
    align-content: flex-start;
    width: 40%;
    height: auto;
    ${"" /* font-size: 1em; */}
    flex-grow: 10;
    padding: 0;
    overflow: hidden;
  }

  @media screen and (min-width: ${DEVICE.TABLET_PORTRAIT}) {
    width: 30%;
  }

  @media screen and (min-width: ${DEVICE.TABLET_LANDSCAPE}) {
    width: 43%;
    font-size: 0.9em;

    p {
      width: 80%;
    }
  }

  @media screen and (min-width: ${DEVICE.LAPTOP}) {
    width: 37%;
    font-size: 1.05em;

    p {
      width: 80%;
    }
  }
`;

const Headline = styled.h1`
  display: none;

  @media screen and (min-width: ${DEVICE.PHONE_LANDSCAPE}) {
    display: inline-block;
    ${FONT_STYLE.QUIZ_HEADLINE}
    ${PATTERN.FADEIN}
  }
`;

const Paragraph = styled.p`
  display: none;

  @media screen and (min-width: ${DEVICE.PHONE_LANDSCAPE}) {
    display: inline-block;
    ${FONT_STYLE.QUIZ_BODY}
    ${PATTERN.FADEIN}
    width: 100%;
    margin: 0;
  }

  .nobreak {
    white-space: nowrap;
  }
`;

class Frame extends React.Component {
  componentDidMount() {
    // this.props.checkProgress();
    // this.props.updateClickstream(
    //   this.props.path === "Retirement"
    //     ? data.retirement.frame[this.props.frame].headline
    //     : data.education.frame[this.props.frame].headline
    // );
  }

  // componentDidUpdate(prevState) {
  //   if (this.props.canAdvance !== prevState.canAdvance) {
  //     this.props.checkProgress();
  //   }
  // }

  render() {
    return (
      <CopyContainer>
        <Headline>
          {this.props.path === "Retirement"
            ? data.retirement.frame[this.props.frame].headline
            : data.education.frame[this.props.frame].headline}
        </Headline>
        <Paragraph>
          {parse(
            this.props.path === "Retirement"
              ? data.retirement.frame[this.props.frame].body[0]
              : data.education.frame[this.props.frame].body[0]
          )}
        </Paragraph>
      </CopyContainer>
    );
  }
}

export default Frame;
